<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light is-uppercase">{{ headerText }} ujian</p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            label="Judul"
            custom-class="is-small"
            :type="{'is-danger': errorMap.judul}"
            :message="errorMap.judul"
          >
            <b-input ref="firstInput" v-model="ujian.judul" required @input="validateInput('judul')"></b-input>
          </b-field>

          <b-field
            label="Rotasi"
            custom-class="is-small"
            :type="{'is-danger': errorMap.stase}"
            :message="errorMap.stase"
          >
            <generic-select
              v-model="ujian.stase"
              apiPath="/stase/options/"
              @input="validateInput('stase')"
            ></generic-select>
          </b-field>

          <b-field
            label="Rencana Ujian"
            class="tgl-field"
            custom-class="is-small"
            :type="{'is-danger': errorMap.rencana_ujian}"
            :message="errorMap.rencana_ujian"
          >
            <date-picker v-model="ujian.rencana_ujian" @input="validateInput('rencana_ujian')"></date-picker>
          </b-field>

          <b-field
            label="Tanggal Maju"
            class="tgl-field"
            custom-class="is-small notrequired"
            :type="{'is-danger': errorMap.tanggal_ujian}"
            :message="errorMap.tanggal_ujian"
          >
            <date-picker v-model="ujian.tanggal_ujian" @input="validateInput('tanggal_ujian')"></date-picker>
          </b-field>

          <slot />
          <!-- untuk diisi dengan field admin atau preceptor -->

          <b-field
            label="Jenis"
            custom-class="is-small"
            :type="{'is-danger': errorMap.jenis}"
            :message="errorMap.jenis"
          >
            <generic-select
              v-model="ujian.jenis"
              apiPath="/ujian/jenis/"
              @input="validateInput('jenis')"
            ></generic-select>
          </b-field>
          <b-checkbox
            v-model="ujian.mengulang"
            @input="resetErrors()"
          >Mengulang</b-checkbox>

          <b-field
            label="Penguji"
            custom-class="is-small"
            :type="{'is-danger': errorMap.penguji_set}"
            :message="errorMap.penguji_set"
          >
            <b-taginput
              v-model="ujian.penguji_set"
              :data="pengujiTags"
              autocomplete
              field="nama"
              icon="tag"
              @typing="getFilteredPengujiTags"
              @input="validateInput('penguji_set')"
            ></b-taginput>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <div class="field is-horizontal">
            <div class="field-label">
              <!-- Left empty for spacing -->
            </div>
          </div>
          <div class="buttons">
            <b-button class="is-primary has-text-weight-semibold" @click.stop="cancel">Batal</b-button>
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
            >Simpan</b-button>
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import axios from "axios";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Ujian from "../models/cuUjian.js";


export default {
  name: "UjianCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
    DatePicker: () => import("@/apps/core/components/DatePicker.vue")
  },
  data() {
    this.ujianMdl = new Ujian();
    this.objectMdl = this.ujianMdl; // alias yg digunakan di cuMixin
    let obv = this.ujianMdl.getObservables();
    obv.konsulenList = []; // bisa di-bind ke this (tdk perlu obv)
    obv.pengujiTags = [];
    return obv;
  },
  computed: {
    stase() {
      return this.ujian.stase;
    }
  },
  methods: {
    fetchKonsulen() {
      const konsulenURL = `${APP_CONFIG.baseAPIURL}/anggota/dosen/`;
      axios
        .get(konsulenURL, { params: { kognitif: "Ujian" } })
        .then(response => {
          this.konsulenList = response.data;
        })
        .catch(() => {
          this.konsulenList = [];
        });
    },
    getFilteredPengujiTags(text) {
      this.pengujiTags = this.konsulenList.filter(option => {
        return option.nama.toLowerCase().indexOf(text.toLowerCase()) >= 0;
      });
    },
    resetErrors() {
      this.setEdited(true);
    },
    onSaved(respObj) {
      if (this.isCreate) {
        this.$router.replace({
          name: "ujian-detail",
          params: { id: respObj.id }
        });
      } else {
        this.$router.go(-1);
      }
    }
  },
  watch: {
    ujian: {
      // ujian berubah, update edited
      handler(newValue, oldValue) {
        if (!this.ujianMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    },
    stase: {
      handler(newValue, oldValue) {
        if (oldValue && this.ujian.penguji_set.length > 0) {
          this.setEdited(true);
          this.validateInput("penguji_set");
        }
      },
      deep: true
    }
  },
  created() {
    this.fetchKonsulen();
  }
};
</script>
